import * as React from "react";
import {useRef} from 'react'
import {getImage, withArtDirection} from "gatsby-plugin-image";
import {graphql, PageProps} from "gatsby"
import {useIntersectionObserver} from 'usehooks-ts'

import TemplateHead from "../components/template-head";
import Layout from "../components/layout";
import Hero from "../components/hero";
import Lawyer from "../components/lawyer";
import TopCities from "../components/cities-top";
import TrustpilotCarousel from "../components/trustpilot/trustpilot-carousel";
import HelpSlider from "../components/slider/help-slider";
import HeroContentHeading from "../components/hero-content-heading";
import FuneralPackageGrid from "../components/funeral-package-grid";

import "../styles/page-home.scss";

type DataProps = {
  topCitiesBg: any,
  homePageData: any,
}

export default function Home({
                               data: {
                                 topCitiesBg,
                                 homePageData,
                               }
                             }: PageProps<DataProps>) {
  const ref = useRef<HTMLDivElement | null>(null)
  const entry = useIntersectionObserver(ref, {threshold: 0.3, freezeOnceVisible: true})
  const isVisible = !!entry?.isIntersecting

  const imagesHero = withArtDirection(getImage(getImage(homePageData?.imageHero?.localFile)), [
    {
      media: "(max-width: 719px)",
      image: getImage(homePageData?.imageHeroMobile?.localFile),
    },
  ])
  const imageService = getImage(homePageData.imageOurServices?.localFile)
  const imageAbout = getImage(homePageData.imageAboutFenix?.localFile)

  return (
    <Layout>
      <Hero
        sectionClassName="hero-section hero-section--home pl-4 pr-4"
        bgImageClassName="hero-image hero-image--home"
        contentClassName="hero-content hero-content--home"
        imageOptimized={imagesHero}
        altImages="Fenix Funeral Directors"
      >
        <HeroContentHeading
          headingTitle={homePageData.title}
          headingContent={homePageData.subtitle}
        />
        <TopCities
          divRef={ref}
          hubData={homePageData.cities}
          topCitiesBg={topCitiesBg?.edges}
        />
      </Hero>
      <HelpSlider
        helpSliderTitle={homePageData.titleSlider}
        helpSlider={homePageData.sliderHelp}
      />
      <Lawyer
        titleAboutFenix={homePageData.titleAboutFenix}
        textAboutFenix={homePageData.textAboutFenix}
        buttonTextAboutFenix={homePageData.buttonTextAboutFenix}
        buttonLinkAboutFenix={homePageData.buttonLinkAboutFenix}
        quoteText={homePageData.quoteText}
        quoteAuthor={homePageData.quoteAuthor}
        titleTransparency={homePageData.titleTransparency}
        textTransparency={homePageData.textTransparency}
        titleOurServices={homePageData.titleOurServices}
        textOurServices={homePageData.textOurServices}
        buttonTextOurServices={homePageData.buttonTextOurServices}
        buttonLinkOurServices={homePageData.buttonLinkOurServices}
        imageAbout={imageAbout}
        imageService={imageService}
        packageGrid={<FuneralPackageGrid
          packagesTitle={homePageData.titleOffers}
        />}
      />
      <TrustpilotCarousel
        titleOurReviews={homePageData.titleOurReviews}
        textOurReviews={homePageData.textOurReviews}
      />
    </Layout>
  );
};

export function Head() {
  return (<TemplateHead></TemplateHead>);
}

const stylesInline: { [key: string]: React.CSSProperties } = {
  emptyLongSection: {
    height: "3600px",
  },
  emptySlider: {
    height: "700px",
  }
};

export const pageQuery = graphql`
  fragment imageDataHomeBg on FileConnection {
    edges {
      node {
        name
        childImageSharp {
          gatsbyImageData(
            placeholder: NONE
            outputPixelDensities: 1
          )
        }
      }
    }
  }
  query {
    homePageData: strapiHomepage {
      title
      subtitle
      strapi_id
      titleTransparency
      textTransparency
      titleSlider
      titleOurServices
      textOurServices {
        data {
          textOurServices
        }
      }
      titleOffers
      titleAboutFenix
      textAboutFenix {
        data {
          textAboutFenix
        }
      }
      quoteText
      quoteAuthor
      sliderHelp {
        title
        textMain
        textButton
        linkButton
        icon {
          url
        }
      }
      buttonLinkAboutFenix
      buttonTextAboutFenix
      buttonTextOurServices
      buttonLinkOurServices
      imageHero {
        localFile {
          childImageSharp {
            gatsbyImageData(
            placeholder: NONE,
            outputPixelDensities: 1
            )
          }
        }
      }
      imageHeroMobile {
        localFile {
          childImageSharp {
            gatsbyImageData(
                placeholder: NONE,
                outputPixelDensities: 1
            )
          }
        }
      }
      imageAboutFenix {
        localFile {
          childImageSharp {
            gatsbyImageData(
                placeholder: NONE,
                outputPixelDensities: 1
            )
          }
        }
      }
      imageOurServices {
        localFile {
          childImageSharp {
            gatsbyImageData(
              placeholder: NONE,
              width: 690,
              height: 585,
              outputPixelDensities: 1,
            )
          }
        }
      }
      cities {
        title
        cityUrl
        image {
          middle {
            localFile {
              childImageSharp {
                gatsbyImageData(
                  placeholder: NONE,
                  width: 460,
                  height: 650,
                  outputPixelDensities: 1,
                  transformOptions: {cropFocus: CENTER, fit: COVER}
                )
              }
            }
          }
        }
      }
    }
    topCitiesBg: allFile(filter: {name: {in: ["bgMap"]}}) {
      ...imageDataHomeBg
    }
  }
`;