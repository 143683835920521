import * as React from "react";
import {useState} from "react";
import {Pagination} from "swiper";
import {Swiper, SwiperSlide} from 'swiper/react';

export default function HelpSlider(props: any) {
  const [pagesEl, setPagesEl] = useState<HTMLElement | null>(null)
  return (
    <div className="help-section bg-deepblue pl-0 pr-0 pl-sm-4 pr-sm-4">
      <div className="help-section__content content-container">
        <h2
          className="text-white pl-4 pr-4 pl-sm-5 pr-sm-0">{props.helpSliderTitle ? props.helpSliderTitle : "How can we help you?"}</h2>
        <Swiper className={'help-section__container'}
                modules={[Pagination]}
                updateOnWindowResize={true}
                observer={true}
                observeParents={true}
                spaceBetween={15}
                pagination={{
                  clickable: true,
                  el: pagesEl
                }}
                breakpoints={{
                  320: {
                    slidesPerView: 1.4,
                    grabCursor: true,
                    keyboard: true,
                    allowTouchMove: true,
                    mousewheel: true,
                    simulateTouch: true,
                    slidesOffsetAfter: 25,
                    slidesOffsetBefore: 25
                  },
                  719: {
                    slidesPerView: 3,
                    grabCursor: false,
                    keyboard: false,
                    allowTouchMove: false,
                    mousewheel: false,
                    simulateTouch: false,
                    centeredSlides: false,
                  },
                  1200: {
                    slidesPerView: 3,
                    grabCursor: true,
                    keyboard: false,
                    allowTouchMove: false,
                    mousewheel: false,
                    simulateTouch: false,
                  }
                }}
        >
          {
            props.helpSlider.map((item: any, index: any) => (
              <SwiperSlide key={index} className={'help-section__block text-center bg-white'}>
                <div className="help-section__heading">
                  <img src={item.icon.url} alt={item.title} width="100" height="100"/>
                  <h3 className="text-deepblue">{item.title}</h3>
                  <p className="text-deepblue">{item.textMain}</p>
                </div>
                <a href={item.linkButton} title={item.textButton}>{item.textButton}</a>
              </SwiperSlide>
            ))
          }
        </Swiper>
        <div ref={(node) => setPagesEl(node)}
             className="swiper-pagination swiper-pagination--help swiper-pagination-clickable swiper-pagination-bullets"></div>
      </div>
    </div>
  );
}